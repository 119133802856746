import {ApiMethod, DofApi} from "@/managers/net/Api";
import IApi from "@/managers/net/i-api";
import {DOF_API_BASE_URL} from "@/request-apis/dof/dof-api-base";

export class ApiDofUserGetInfo extends DofApi<any> implements IApi {
    public path = DOF_API_BASE_URL + "/user/get_user_info.php";
    public method = ApiMethod.GET;
    public constructor(query:{user:string}) {
        super(query);
    }
}

export class ApiDofUserGetCharactors extends DofApi<any> implements IApi {
    public path = DOF_API_BASE_URL + "/user/get_user_charactors.php";
    public method = ApiMethod.GET;
    public constructor() {
        super();
    }
}

type ApiDofUserRequestBindAccountQuery = {
    type: 'request',
    username: string
} & {
    type: 'verify',
    code: string
} & {
    type: 'status',
};

export class ApiDofUserRequestBindAccount extends DofApi<any> implements IApi {
    public path = DOF_API_BASE_URL + "/user/request_bind_account.php";
    public method = ApiMethod.GET;
    public constructor(query: any) {
        super(query);
    }
}
