



































































import {Vue, Component} from 'vue-property-decorator';
import SimpleLayout from "@/views/layouts/SimpleLayout.vue";
import UserManager, {UserEvent} from "@/managers/user-manager";
import {ApiDofUserRequestBindAccount} from "@/request-apis/dof/user/dof-user-api";

@Component({
  components: {SimpleLayout}
})
export default class DofBindAccounts extends Vue {
  private status = "loading";
  private errCode = "";
  private errMsg = "";
  private curStep = 0;
  private inputUsername = "";
  private inputUsernameLoading = false;
  private codeHintTitle:string = "已经向您提供用户名的角色发送验证码，请填写在下面的输入框中。";
  private inputCode = "";
  private inputCodeLoading = false;

  public created() {
    document.title = "账号绑定 - 米萌DNF";
  }

  private mounted() {
    UserManager.getInstance().on(UserEvent.ON_LOGIN_STATE_REFRESH, this, this.onLoginStateRefresh);
    this.onLoginStateRefresh();
  }

  private destroyed() {
    UserManager.getInstance().off(UserEvent.ON_LOGIN_STATE_REFRESH, this, this.onLoginStateRefresh);
  }

  private onLoginStateRefresh() {
    this.refreshStatus();
  }

  private async onGetCodeClick() {
    this.inputUsernameLoading = true;
    try {
      if(this.inputUsername.length <= 0) {
        throw { msg: "用户名不能为空" }
      }
      const res = await new ApiDofUserRequestBindAccount({type: 'request', username: this.inputUsername}).run();
      const name = res.result.charactor.name;
      const lv = res.result.charactor.lv;
      this.codeHintTitle = `已向您的游戏角色[${name}(Lv.${lv})]发送了验证代码，请查收游戏邮件`;
      this.refreshStatus();
    } catch (e) {
      this.showErrorMessage(e.msg);
    }
    this.inputUsername = "";
    this.inputUsernameLoading = false;
  }

  private async onVerifyClick() {
    this.inputCodeLoading = true;
    try {
      if(this.inputCode.length <= 0) {
        throw { msg: "验证代码不能为空" }
      }
      await new ApiDofUserRequestBindAccount({type: 'verify', code: this.inputCode}).run();
    } catch (e) {
      this.showErrorMessage(e.msg);
    }
    this.inputCode = "";
    this.inputCodeLoading = false;
    this.refreshStatus();
  }

  private async refreshStatus() {
    console.log("refreshStatus");
    const loginInfo = UserManager.getInstance().getLoginUserInfo();
    if(loginInfo) {
      this.status = "loading";
      const res = await new ApiDofUserRequestBindAccount({type: "status"}).run();
      if(res.code == "success") {
        this.status = res.result.status;
        this.refreshStepIndex();
      } else {
        this.setError(res.code, res.msg);
      }
    } else {
      this.setError("no_login", "您还没有登录，请先登录。");
    }
  }

  private refreshStepIndex() {
    if(this.status == "username") {
      this.curStep = 0;
    } else if(this.status == "code") {
      this.curStep = 1;
    } else if(this.status == "finished") {
      this.curStep = 2;
    } else {
      this.curStep = -1;
    }
  }

  private setError(code:string, msg:string) {
    this.status = 'error';
    this.errCode = code;
    this.errMsg = msg;
  }

  private showErrorMessage(msg:string) {
    const self = this;
    self.$error({
      title: '错误',
      content: msg,
      okText: '确定',
    })
  }

}
